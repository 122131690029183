@import "./mixins";

$white: #ffffff;
$brand100: #f5f4ff;
$brand200: #e5dfff;
$brand400: #978eff;
$brand500: #7c60ff;
$brand600: #5c42d6;
$error: #ff5757;
$blue100: #f9fafd;
$blue150: #eef1f7;
$blue200: #dde2ee;
$blue300: #bfc7d9;
$blue400: #949eb7;
$blue500: #636c83;
$blue600: #434959;
$blue700: #2f333e;
$blue800: #20232a;
$blue850: #181a1f;
$blue900: #131418;
$black: #000000;
$dim: rgba($black, 0.5);

$colors:
  "white" #ffffff,
  "brand100" #f5f4ff,
  "brand200" #e5dfff,
  "brand400" #978eff,
  "brand500" #7c60ff,
  "brand600" #5c42d6,
  "error" #ff5757,
  "warn" #f68b00,
  "success" #00b252,
  "g100" #f9fafd,
  "g150" #eef1f7,
  "g200" #dde2ee,
  "g300" #bfc7d9,
  "g400" #949eb7,
  "g500" #636c83,
  "g600" #434959,
  "g700" #2f333e,
  "g800" #20232a,
  "g850" #181a1f,
  "g900" #131418,
  "black" #000000,
  "get" #01803c,
  "post" #b87723,
  "put" #0057b3,
  "patch" #683593,
  "delete" #9d0713,
  "head" #46900c,
  "options" #b70065;

@each $color, $code in $colors {
  .bg-#{$color} {
    background-color: $code;
    background: $code;
  }
  .#{$color} {
    color: $code;
  }
  .b-#{$color} {
    border: 1px solid $code;
  }
  .bt-#{$color} {
    border-top: 1px solid $code;
  }
  .br-#{$color} {
    border-right: 1px solid $code;
  }
  .bl-#{$color} {
    border-left: 1px solid $code;
  }
  .bb-#{$color} {
    border-bottom: 1px solid $code;
  }
}

.tooltip:hover {
  color: $brand500;
}
.tooltip:hover .non {
  display: none;
}
.tooltip .hover {
  display: none;
}
.tooltip:hover .hover {
  display: block;
}

.btn-pri {
  background-color: $brand500;
  color: $white;
  cursor: pointer;
  &:hover {
    background-color: $brand600;
  }
  &.disable {
    background-color: $brand200;
    cursor: not-allowed;
  }
}

.btn-sec {
  background-color: $blue150;
  color: $blue600;
  cursor: pointer;
  &:hover {
    background-color: $blue200;
  }
  &.disable {
    background-color: $blue150;
    color: $blue300;
    cursor: not-allowed;
  }
}
.btn-line {
  background-color: $blue100;
  border: 1px solid $blue300;
  color: $blue500;
  cursor: pointer;
  &:hover {
    background-color: $blue200;
  }
  &.disable {
    background-color: $blue100;
    border: 1px solid $blue300;
    color: $blue300;
    cursor: not-allowed;
  }
}

.caret-color {
  input {
    caret-color: $brand500;
  }
  .ant-input-affix-wrapper > input.ant-input {
    font-size: 13px;
    margin-top: 3px;
    height: 17px;
  }
}
