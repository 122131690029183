.projectManager {
  min-height: 1080px;
  height: 100vh;

  @include tablet-pt {
    min-height: 1024px;
  }
  .test {
    display: none;
  }
  .project-header {
    @include flex-c(space-between);
    border-bottom: 1px solid $blue200;
    height: 48px;
    background-color: $white;
    padding: 0 24px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    // 헤더 - Tooltip
    .help-icon-body {
      margin: 10px 5px;
    }
  }
  .ant-layout-sider {
    &.subSider {
      display: none;
      &.true {
        display: block;
        @include tablet-pt {
          position: absolute;
          z-index: 998;
          min-height: calc(1024px - 48px);
        }
      }
    }
    &.project-sider {
      min-width: 80px !important;
      max-width: 80px !important;
      width: 80px !important;
      @include tablet-pt {
        min-width: 48px !important;
        max-width: 48px !important;
        width: 48px !important;
      }
      .menu-box {
        height: 84px;
        width: 72px;
        text-align: center;
        padding: 25px 0;
        @include tablet-pt {
          width: 40px;
          height: 48px;
          padding: 17px 10px;
          .menu-desc {
            display: none;
          }
        }
        // sider 저장필요 아이콘
        .active-icon {
          position: absolute;
          height: 6px;
          width: 6px;
          border-radius: 100px;
          top: 6px;
          right: 6px;
          background-color: $brand500;
        }
      }
    }
  }
  .project-body {
    // height: calc(100vh - 48px);
  }
  .project-api-content {
    height: 100%;
    &.isSubSiderOpen-true {
      @include tablet-lg {
        .ant-table-wrapper {
          overflow: auto;
          .ant-table {
            width: 863px;
          }
        }
      }
    }
    .project-api-content-right {
      width: calc(100% - 49px);
      height: 100%;
      &.isRightbarOpen-true {
        width: calc(100% - 441px);
        @include tablet-lg {
          width: calc(100% - 49px);
        }
        @include tablet-pt {
          width: calc(100% - 49px);
        }
      }
    }

    @include tablet-pt {
      .ant-table-wrapper {
        overflow: auto;
        .ant-table {
          width: 863px;
        }
      }
    }
    .api-content-subtab {
      cursor: pointer;
      font-family: "roboto_B";
      font-size: 13px;
      line-height: 18px;
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 3px;
      &.true {
        border-bottom: 1.5px solid $brand500;
        color: $blue900;
      }
      &.false {
        color: $blue400;
      }
    }
    .api-url-table {
      width: 100%;
      &.isSubSiderOpen-true {
        @include tablet-lg {
          .apiurl-first {
            width: 25%;
          }
          .apiurl-sec {
            width: 75%;
          }
        }
      }
      .apiurl-first {
        width: 10%;
        @include tablet-pt {
          width: 20%;
        }
        @include tablet-lg {
          width: 15%;
        }
      }
      .apiurl-sec {
        width: 90%;
        @include tablet-pt {
          width: 80%;
        }
        @include tablet-lg {
          width: 85%;
        }
      }
    }
  }
  .project-user-content {
    height: 100%;
    .user-content-table {
      @include desktop {
        min-width: 1510;
      }
    }
  }
}

//ApiContent
.api-subtab {
  width: 170px;
  height: 100%;
  display: inline-flex;
  cursor: pointer;
  border: 1px solid $blue200;
  color: $blue400;
  background-color: $blue100;
  &.true {
    color: $blue900;
    margin-top: 1px;
    border-bottom: 1px solid $brand500;
    background-color: $white;
  }
  &.isSubSiderOpen-true {
    @include tablet-lg {
      width: 120px;
    }
  }
  &:nth-child(2) {
    border-left: 0;
    border-right: 0;
  }

  @include tablet-pt {
    width: 120px;
  }
}

// TabHeader
.tab-active {
  color: #7c60ff !important;
}

// TabHeader
.interaction {
  background: #7c60ff;
  &.act {
    border-radius: 100px;
    margin: 4px;
    width: 9px;
    height: 9px;
  }
}

@keyframes smartAnimate {
  0% {
    background-color: $brand500;
  }
  100% {
    background-color: $brand100;
  }
}

// TabHeader - Dropdown radius, bg-color, color 수정
.ant-dropdown-menu {
  border-radius: 3px !important;
  border: 1px solid $blue300;
  .ant-dropdown-menu-item-active {
    background-color: $white !important;
    .ant-dropdown-menu-title-content {
      div {
        color: $brand500 !important;
      }
    }
  }
}

// TabHeader - 프로젝트 매니저 > API > 최상단 탭
.tab-header {
  width: 100%;
  height: 48px;
  position: relative;
  @include flex-c(space-between);
  .tab-over {
    &.left {
      display: none;
    }
  }
  .tab-drop-btn {
    cursor: pointer;
    background-color: $white;
    height: 100%;
    border-left: 1px solid $blue200;
    border-bottom: 1px solid $blue200;
    border-right: 1px solid $blue200;
    position: absolute;
    padding: 16px 10px;
    top: 0;
    right: -1px;
    // margin: 10px;
  }
  #tab-mid {
    display: inline-flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  &.overflow {
    .more {
      display: none;
    }
    #tab-mid {
      padding-left: 36px;
      width: calc(100% - 110px);
    }

    .tab-over {
      display: block;
      &.left {
        height: 100%;
        background-color: $white;
        border-right: 1px solid $blue200;
        border-bottom: 1px solid $blue200;
        width: 36px;
        position: absolute;
        padding: 16px 10px;
        cursor: pointer;
        img {
          cursor: pointer;
        }
      }
      &.right {
        width: 36px;
        height: 100%;
        position: absolute;
        padding: 6px 0;
        top: 0;
        right: 71px;
        background-color: $white;
        border-left: 1px solid $blue200;
        border-bottom: 1px solid $blue200;
        cursor: pointer;
        img {
          margin: 10px;
        }
      }
      &.right-close {
        width: 36px;
        height: 100%;
        position: absolute;
        padding: 6px 0;
        top: 0;
        right: 36px;
        background-color: $white;
        border-left: 1px solid $blue200;
        border-bottom: 1px solid $blue200;
        cursor: pointer;
        img {
          margin: 10px;
        }
      }
    }
    .tab-drop-btn {
      border-left: 1px solid $blue200;
    }
  }
  &.overflow-more {
    .more {
      display: none;
    }
    #tab-mid {
      width: calc(100% - 108px + 37px);
    }
    .tab-over {
      &.right-close {
        width: 36px;
        height: 100%;
        position: absolute;
        padding: 6px 0;
        top: 0;
        right: 36px;
        background-color: $white;
        border-left: 1px solid $blue200;
        border-bottom: 1px solid $blue200;
        cursor: pointer;
        img {
          margin: 10px;
        }
      }
    }
  }

  // 상단 텝
  .tabs {
    cursor: pointer;
    display: inline-flex;
    min-width: 112px;
    max-width: 186px;
    height: 48px;

    .tabs-content {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      .tabs-content-act {
        height: 100%;
        @include flex-c(space-between);

        .tabs-content-act-http {
          margin-top: 2px;
          font-family: "roboto_B";
          font-size: 12px;
        }

        .tabs-content-act-title {
          width: calc(100% - 26px);
          margin-left: 4px;
          @include text-ellipsis;
        }
      }
    }

    .right-bar {
      border-right: 1px solid $blue200;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}

// TabHeader - tab 드롭다운
.tab-drop {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;

  .tab-http {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "roboto_B";
    margin-right: 4px;
    width: 38px;
    display: inline-block;
    text-align: right;
  }
  .tab-title {
    @include text-ellipsis;
    display: inline-block;
    color: $blue400;
    width: 246px;
  }

  &:hover {
    .tab-title {
      width: 230px;
      color: $blue900;
    }
  }
  &.update {
    .tab-title {
      width: 228px;
    }
  }
}

// TabHeader - 마우스 오버시 close icon 표시 & update display none
.tab-act {
  img {
    display: none;
  }
  &:hover {
    img {
      display: block;
    }
    .act {
      display: none;
    }
  }
}

// 프로젝트 매니저 > API > 공통변수
.common-variable {
  padding: 20px;
  .common-variable-content {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    height: 36px;
    @include flex-c(space-between);
    .common-variable-content-subtab {
      cursor: pointer;
      font-family: "roboto_B";
      font-size: 13px;
      line-height: 18px;
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 3px;
      &.true {
        border-bottom: 1.5px solid $brand500;
        color: $blue900;
      }
      &.false {
        color: $blue400;
      }
    }
  }
}

// 프로젝트 매니저 > API > 공통에러
.common-error {
  height: 100%;
  padding: 16px;
  position: relative;
  @include tablet-pt {
    .ant-table-wrapper {
      overflow: auto;
      .ant-table {
        width: 912px;
      }
    }
  }

  .common-error-tabs {
    border-bottom: 1px solid $blue200;
    height: 40px;
    .common-error-tab {
      width: 170px;
      height: 100%;
      display: inline-flex;
      cursor: pointer;
      border: 1px solid $blue200;
      color: $blue400;
      background-color: $blue100;
      &.true {
        color: $blue900;
        margin-top: 1px;
        border-bottom: 1px solid $brand500;
        background-color: $white;
      }
      &:nth-child(2) {
        border-left: 0;
      }
    }
    .common-error-btn {
      position: absolute;
      top: 10px;
      right: 16px;
    }
  }
  .common-error-content {
    margin-top: 24px;
    margin-bottom: 8px;
    .common-error-content-subtab {
      cursor: pointer;
      font-family: "roboto_B";
      font-size: 13px;
      line-height: 18px;
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 3px;
      &.true {
        border-bottom: 1.5px solid $brand500;
        color: $blue900;
      }
      &.false {
        color: $blue400;
      }
    }
  }
}

.subtab-title {
  font-family: "roboto_B";
  font-size: 13px;
}

// Rightbar - 프로젝트 매니저 > API > Rightbar
.rightbar {
  height: 100%;
  border-left: 1px solid $blue200;
  width: 48px;
  background-color: white;
  &.active {
    width: 440px;
    @include tablet-lg {
      position: absolute;
      z-index: 998;
      top: 0px;
      right: 0px;
    }
    @include tablet-pt {
      position: absolute;
      z-index: 998;
      top: 0px;
      right: 0px;
    }
  }
  @include bar-icon("");

  .open {
    height: 100%;
    font-family: "roboto_B";
    @include flex-c(space-between);
    @include bar-icon(100%);

    .right-body {
      height: 100%;
      padding: 8px;
      width: calc(440px - 48px);

      .header {
        @include flex-c(space-between);
        margin-bottom: 8px;
        height: 32px;
        font-size: 14px;
      }

      .content-header {
        @include flex-c(space-between);
        margin-bottom: 14px;
        img {
          cursor: pointer;
        }
        .code-select {
          padding: 8px;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            background-color: $blue150;
          }
          .space {
            font-family: "roboto_B";
            img {
              margin-bottom: 4px;
            }
          }
        }
        .code-icon img {
          cursor: pointer;
          &:last-child {
            margin-left: 16px;
          }
        }
      }
    }

    .right-content {
      background-color: $blue100;
      margin-bottom: 12px;
      padding: 13px;
      border-radius: 3px;

      .code {
        @include flex-c(space-between);
        align-items: stretch;
        font-family: "roboto_R";
        line-height: 18px;
        .num {
          color: $blue300;
          margin-right: 8px;
        }
        .word {
          width: 100%;
        }
      }

      .reqresp {
        font-family: "roboto_R";
        white-space: pre;
        line-height: 18px;
        color: $blue900;
      }
    }
  }
}

//treenode line
.draggable-tree {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 400;
  .ant-tree-indent-unit-end::before {
    display: inline;
  }
  .ant-tree-draggable-icon {
    display: none;
  }
  .ant-tree-treenode-selected {
    background-color: $blue200;
    border-left: 2px solid #7c60ff;
  }
  .ant-tree-node-content-wrapper {
    .IconTest {
      display: none;
    }
    &:active,
    &:focus,
    &.ant-tree-node-selected {
      // data에서 icon의 classname을 IconTest로
      .IconTest {
        display: inline;
      }
      .drag-icon {
        display: none;
      }
    }
  }
}

//user subsider table
.table {
  &.user {
    tr:hover {
      background-color: $blue200;
      cursor: pointer;
    }
    .active {
      background-color: $blue200;
      .bor {
        border-left: 2px solid #7c60ff;
      }
    }
  }
}

//user content tree
.tree {
  &.user {
    .ant-tree-treenode {
      border-left: 1px solid $blue200;
      border-right: 1px solid $blue200;
      border-bottom: 1px solid $blue200;
    }
  }
}

// InviteProjectUserTable
.dividing-line {
  width: 282px;
  height: 1px;
  margin: 5px;
  background: #dde2ee;
}

.input-B {
  input {
    font-weight: 600;
  }
}

// ApiUrlContent
.url-mid {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(239px - 32px);
  font-family: "roboto_B";
}

.editable-cell-value-wrap {
  display: flex-start;
  align-items: center;
}

#treeTable {
  border-spacing: 0;
  border-collapse: collapse;

  tr {
    height: 36px;
    #first {
      padding-left: 16px;
    }
    #mid {
      padding-left: 30px;
    }
    #last {
      padding-right: 16px;
    }
  }
  .selected-key {
    background-color: $blue200;
  }
}
