// 고객지원
.support {
  height: calc(100vh);
  min-height: 1080px;
  .support-body {
    display: flex;
    justify-content: space-between;
    padding: 72px 0;
    top: 60px;
    position: absolute;
    @include tablet-lg {
      padding: 56px 0;
    }
    @include tablet-pt {
      padding: 36px 0;
    }
    min-height: 900px;
    .support-name {
      @include typo(36px, 48px, -0.5px, 600);
      color: $blue900;
      margin-top: 4px;
      margin-bottom: 36px;
      &.tablet {
        display: none;
        @include tablet-pt {
          @include typo(28px, 38px, -0.5px, 600);
          display: block;
          margin-bottom: 29px;
        }
      }
    }
    .support-menu {
      width: 200px;
      @include tablet-pt {
        display: none;
      }
    }
    .support-content {
      width: 990px;
      height: 100%;
      display: inline-block;
      @include tablet-lg {
        width: 772px;
      }
      @include tablet-pt {
        width: 696px;
      }
      .support-content-header {
        @include flex-c(space-between);
        @include tablet-pt {
          display: inline-flex;
        }
      }
      .support-content-title {
        @include flex-c("");
        @include typo(24px, 30px, -0.5px, 600);
        color: $blue900;
        height: 48px;
        @include tablet-pt {
          display: none;
        }
      }
      .support-tablet-menu {
        p {
          margin-left: 26px;
          margin-bottom: 29px;
        }
        display: none;
        @include tablet-pt {
          display: inline-flex;
        }
      }
      .support-search-input {
        @include flex-c("");
        @include typo(18px, 24px, 0);
        height: 48px;
        color: $blue400;
        border: 1px solid $blue200;
        background-color: $blue150;
        input {
          background-color: $blue150;
        }
      }
      .support-search-desktop {
        width: 560px;
        @include tablet-pt {
          display: none;
        }
      }
      .support-search-tablet {
        width: 696px;
        display: none;
        @include tablet-pt {
          display: flex;
        }
      }
    }
  }
}

//고객지원 테이블
.support-table {
  td {
    border-bottom: 1px solid $blue200;
  }
  &.detail {
    .first {
      width: 15%;
      @include desktop {
        width: 10%;
      }
    }
    .sec {
      width: 70%;
      @include desktop {
        width: 80%;
      }
    }
    .last {
      width: 15%;
      @include desktop {
        width: 10%;
      }
    }
    .support-detail {
      width: 765px;
      @include tablet-lg {
        width: 563px;
      }
      @include tablet-pt {
        width: 490px;
      }
    }
  }
  &.all {
    .first {
      width: 10%;
      @include tablet-pt {
        width: 15%;
      }
    }
    .sec-one {
      width: 70%;
      @include tablet-pt {
        width: 55%;
      }
    }
    .thr-one {
      width: 10%;
      @include tablet-pt {
        width: 15%;
      }
    }
    .sec {
      width: 80%;
      @include tablet-pt {
        width: 70%;
      }
    }
    .last {
      width: 10%;
      @include tablet-pt {
        width: 15%;
      }
    }
    tr:first-child {
      border-top: 1px solid $blue200;
    }
    .isOneToOne {
      width: 765px;
      @include tablet-lg {
        width: 563px;
      }
      @include tablet-pt {
        width: 490px;
      }
      &.true {
        width: calc(756px - 100px);
        @include tablet-lg {
          width: calc(563px - 100px);
        }
        @include tablet-pt {
          width: calc(490px - 100px);
        }
      }
    }
  }
}
