// 사용가이드
.manual {
  height: calc(100vh);
  @include tablet-pt {
    height: 100%;
    .not-btn {
      width: 696px;
    }
  }
  @include tablet-lg {
    min-height: 1366px;
    .not-btn {
      width: 700px;
    }
  }
  @include desktop {
    min-height: 1080px;
  }
  @include desktop-lg {
    min-height: calc(100vh);
  }
  // 사용가이드 사이더
  .manual-sider {
    @include tablet-pt {
      display: none;
    }
    border-right: 1px solid $blue200;
    background-color: $blue150;
    .ant-menu-item-selected {
      font-weight: 600;
    }
    .ant-menu-submenu-arrow {
      position: static;
    }
    .ant-menu-inline.ant-menu-root
      .ant-menu-submenu-title
      > .ant-menu-title-content {
      flex: none;
      margin-right: 10px;
    }
    .manual-sider-title {
      @include flex-c("");
      @include typo(20px, 26px, -0.5px, 600);
      color: $blue400;
      margin-left: 20px;
      height: 70px;
    }
    .manual-sider-menu {
      @include typo(14px, 20px, 0);
      color: $blue500;
    }
  }
  // 사용가이드 내용
  .manual-body {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    min-height: 900px;
    height: calc(100% - 120px - 60px);
    top: 60px;
    position: absolute;
    @include tablet-pt {
      position: static;
      min-height: calc(1024px - 60px - 176px);
      height: 100%;
    }
    @include tablet-lg {
      position: static;
      height: calc(100%);
      justify-content: inherit;
    }
    @include desktop {
    }
    .manual-content {
      display: inline-block;
      height: 100%;
      width: 816px;
      @include tablet-pt {
        width: 696px;
      }
      @include tablet-lg {
        width: 700px;
      }
      @include desktop-lg {
        width: 990px;
      }
      .search {
        border: 1px solid $blue200;
        background-color: $blue150;
        color: $blue400;
        height: 48px;
        @include typo(18px, 24px, 0);
        input {
          background-color: $blue150;
        }
      }
      .search-list {
        border: 1px solid $blue200;
        border-radius: 4px;
        margin-top: 4px;
        background-color: $white;
        position: absolute;
        @include tablet-pt {
          width: 696px;
        }
        @include tablet-lg {
          width: 700px;
        }
        @include desktop {
          width: 816px;
        }
        .search-list-block {
          color: $blue900;
          border-bottom: 1px solid $blue200;
          cursor: pointer;
          padding: 16px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
      // 이용자가이드 스크롤바 scss
      .guide::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      .guide::-webkit-scrollbar-thumb {
        outline: none;
        border-radius: 10px;
        border: 3px solid transparent;
        /* box-shadow 색상은 지정 안했을 경우 기본적으로 color 값을 따라감 */
        box-shadow: inset 6px 6px 0;
      }
      .guide::-webkit-scrollbar-track {
        box-shadow: none;
        background-color: transparent;
      }
      .guide.scroll-hidden {
        color: transparent;
      }

      .guide {
        margin-top: 24px;
        color: #666666;
        overflow-y: auto;
        overflow-x: hidden;
        // 전체 높이에서 헤더, 푸터 제외 하고 content 길이만 해당
        // height: calc(100vh);
        @include tablet-pt {
          overflow: hidden;
          height: calc(100%);
        }
        @include tablet-lg {
          height: calc(100% - 60px);
        }
        @include desktop {
          // height: calc(100% - 60px - 120px - 48px - 70px);
          height: calc(100% - 60px);
        }
        .guide-header {
          border-bottom: 1px solid $blue200;
        }
        @include typo(16px, 22px, 0);
        .guide-title {
          padding: 24px 0;
          color: $blue900;
          h3 {
            @include typo(36px, 48px, -0.5px, 600);
            margin-bottom: 12px;
          }
          p {
            @include typo(16px, 24px, 0, 300);
            color: $blue500;
          }
          p2 {
            @include typo(14px, 24px, 0, 300);
            color: $blue500;
          }
        }
        .guide-content {
          color: $blue900;
          div {
            margin-bottom: 12px;
          }
          .manual-table-data {
            table,
            tr,
            td {
              border: 1px solid $blue200;
              padding: 8px 12px;
            }
          }
        }
        .guide-btn {
          color: $blue900;
          @include flex-c(center);
          margin: 28px 0;
        }
      }
    }

    //사용가이드 내용 네비게이션
    .nav {
      display: none;
      color: $blue500;
      width: calc(218px + 68px);
      padding: 85px 0 0 65px;
      p {
        padding: 10px 12px;
      }
      a {
        text-decoration: none;
        color: $blue500;
      }
      .active {
        color: #7c60ff;
        font-weight: 600;
      }
      .nav-title {
        font-weight: 600;
      }
      .sub {
        padding: 10px 26px;
      }
    }
    @include desktop {
      .nav {
        display: block;
      }
    }
  }
}

// 헤더 햄버거 드로어
.drawer {
  @include flex-c("");
  @include typo(20px, 26px, -0.5px, 600);
  color: $blue400;
  .drawer-menu {
    .drawer-padding {
      @include flex-c("");
      padding: 12px;
      color: $blue900;
      @include typo(14px, 20px, 0, 600);
    }
  }
  .drawer-title {
    padding: 19px 21px;
  }
  .manual-sider-menu {
    @include typo(14px, 20px, 0);
    color: $blue500;
    .ant-menu-submenu-title {
      padding-left: 0;
    }
  }
  .ant-menu-item-selected {
    font-weight: 600;
  }
  .ant-menu-submenu-arrow {
    position: static;
  }
  .ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content {
    flex: none;
    margin-right: 10px;
  }
  div.ant-menu-submenu-title {
    padding: 10px !important;
  }
}
