@import "./_color.scss";
@import "./mixins";
@import "./_typography.scss";
@import "./variable";
@import "./support";
@import "./manual";
@import "./main";
@import "./account";
@import "./project";
@import "./sign";
@import "./term";

@font-face {
  font-family: "NotoSansKR";
  // font-weight: 50;
  src: url("../fonts/NotoSansKR-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto_R";
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto_B";
  font-weight: 600;
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
}

.App {
  @include tablet-pt {
    min-width: calc($breakpoint-tablet-portrait - (100vw - 100%));
  }
  @include tablet-lg {
    min-width: calc($breakpoint-tablet-large - (100vw - 100%));
  }
  @include desktop {
    min-width: $breakpoint-desktop-large;
  }
}
html {
  height: 100%;
}

h3,
p {
  margin: 0;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.content,
.header,
.sider {
  display: flex;
  align-items: center;
  &.jc-c {
    justify-content: center;
  }
  &.jc-sb {
    justify-content: space-between;
  }
  &.ml-4 {
    margin-left: -4px;
  }
}

.ta {
  text-align: center;
}

.dds .input {
  height: 36px;
  border-radius: 3px;
}

// 고객지원 반응형 적용 시 삭제
.content {
  .search {
    input {
      background-color: $blue150;
    }
  }
}

.cur {
  cursor: pointer;
}

$radius: "2", "3", "4", "5", "8", "10", "20", "100";
@each $size in $radius {
  .br#{$size} {
    border-radius: unquote($size + "px");
  }
}

$height: "36";
@each $size in $height {
  .h#{$size} {
    height: unquote($size + "px");
  }
}

// table radius 0
.ant-table-container {
  border-radius: 0px !important;
}
.ant-table-cell {
  border-radius: 0px !important;
}

// Input Disabled Text Color Black
.ant-input[disabled] {
  color: black;
}

// 공통 Modal
.modal {
  min-width: 528px;
  &.open-true {
    min-width: 100%;
    @include desktop {
      min-width: 990px;
    }
  }
}

// 공통 헤더
.all-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $blue800;
  height: 60px;
  padding: 0 36px;
  z-index: 999;

  .all-header-body {
    @include flex-c(space-between);
    height: 100%;
    .header-content {
      @include flex-c("");
      height: 100%;
      .header-padding {
        height: 60px;
        padding: 20px 20px 20px 20px;
        div {
          color: $white;
          @include typo(14px, 20px, 0, 600);
        }
      }

      &.true {
        @include tablet-pt {
          display: none;
        }
      }
    }
    .hamburger {
      height: 60px;
      padding: 4px 0;
      display: none;
      &.true {
        @include tablet-pt {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}

//공통 푸터
.footer {
  @include flex-c(center);
  border-top: 1px solid $blue200;
  background-color: $white;
  height: 120px;
  padding: 20px 36px;
  @include tablet-pt {
    height: 176px;
    align-items: start;
    justify-content: start;
  }
  .footer-content {
    @include flex-c(space-between);
    color: $blue400;
    @include desktop {
      width: 1194px;
    }
    @include tablet-lg {
      min-width: 952px;
    }
    .right {
      align-self: flex-start;
      @include tablet-pt {
        display: none;
      }
    }
    .down {
      display: none;
      @include tablet-pt {
        display: block;
      }
    }
  }
}

.Toastify__toast-container {
  box-sizing: border-box;
}

.custom-toast {
  --toastify-toast-bottom: 60px;
  --toastify-color-error: #ff5757;
  --toastify-toast-width: 342px;
  --toastify-toast-bd-radius: 3px;
  --toastify-toast-offset: 968px;
}

.ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-table-thead {
  height: 36.62px;
}

$sizes: "100", "140", "160", "182", "200", "220", "230", "250", "300", "525",
  "575", "600", "700", "750", "813", "930", "1200";
@each $size in $sizes {
  .ellipsis-w#{$size} {
    width: unquote($size + "px");
    @include text-ellipsis;
    display: inline-block;
  }
  .ellipsis-mw#{$size} {
    max-width: unquote($size + "px");
    @include text-ellipsis;
    display: inline-block;
  }
  .break-spaces-mw#{$size} {
    max-width: unquote($size + "px");
    white-space: break-spaces;
    display: inline-block;
    margin: 5px;
  }
}

$methods: "GET", "POST", "PUT", "PATCH", "DELETE", "HEAD", "OPTIONS";
@each $method in $methods {
  .api-title {
    color: black;
    @include api-title;
  }
  .api-title-get {
    color: #01803c;
    @include api-title;
  }
  .api-title-post {
    color: #b87723;
    @include api-title;
  }
  .api-title-put {
    color: #0057b3;
    @include api-title;
  }
  .api-title-patch {
    color: #683593;
    @include api-title;
  }
  .api-title-delete {
    color: #9d0713;
    @include api-title;
  }
  .api-title-head {
    color: #46900c;
    @include api-title;
  }
  .api-title-options {
    color: #b70065;
    @include api-title;
  }
}

// 테이블 row 생성시 overflow 스크롤
.table-overflow-auto {
  height: calc(100% - 50px);
  &.-true {
    overflow: auto;
  }
  &.-draggable {
    height: calc(100% - 60px);
    overflow: auto;
    .draggable-tree {
      @include tablet-pt {
        height: calc(1024px - 48px - 56px);
      }
    }
    
  }
  &.-user {
    height: calc(100% - 100px);
    overflow: auto;
  }
}
// 스크롤바 scss
.table-overflow-auto::-webkit-scrollbar {
  width: 11px;
  height: 12px;
}
.table-overflow-auto::-webkit-scrollbar-thumb {
  outline: none;
  border-radius: 10px;
  border: 3px solid transparent;
  /* box-shadow 색상은 지정 안했을 경우 기본적으로 color 값을 따라감 */
  color: #666666;
  box-shadow: inset 6px 6px 0;
}
.table-overflow-auto::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}
.table-overflow-auto.scroll-hidden {
  color: transparent;
}
