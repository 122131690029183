.root {
  .root-main {
    min-width: 100%;
    min-height: 900px;
    overflow: hidden;
    @include desktop {
      .tabl-pt-br,
      .tabl-lg-br {
        display: none;
      }
    }
    @include tablet-lg {
      .desk-br,
      .tabl-pt-br {
        display: none;
      }
    }
    @include tablet-pt {
      .desk-br,
      .tabl-lg-br {
        display: none;
      }
    }
    .body-all-title {
      @include typo(36px, 48px, -0.5px, 600);
      @include tablet-pt {
        @include typo(28px, 38px, -0.5px, 600);
      }
      color: $blue900;
      &.white {
        color: white;
      }
    }
    .body-start {
      @include flex-c(center);
      color: $white;
      text-align: center;
      background-size: cover;
      height: 760px;
      @include tablet-lg {
        height: 600px;
      }
      @include tablet-pt {
        height: 480px;
      }
      .body-start-title {
        font-family: "roboto_B";
        font-size: 48px;
        margin-top: 24px;
        margin-bottom: 6px;
        @include tablet-pt {
          font-size: 36px;
        }
      }
      .body-start-btn {
        @include typo(20px, 26px, -0.5px, 600);
        color: $blue800;
        background-color: $white;
        border-radius: 5px;
        cursor: pointer;
        width: 190px;
        height: 56px;
        &:hover {
          color: $brand500;
          background-color: $blue200;
          .hover-btn {
            display: inline-block;
          }
          .non-btn {
            display: none;
          }
        }
        .hover-btn {
          display: none;
        }
        .body-start-lg {
          display: none;
        }
        .body-start-sm {
          display: inline-block;
        }
        @include desktop {
          width: 250px;
          .body-start-lg {
            display: inline-block;
          }
          .body-start-sm {
            display: none;
          }
        }
      }
    }

    .body-sec {
      @include flex-c(center);
      background-color: $blue150;
      padding: 90px 36px;
      .body-sec-flex {
        display: flex;
        align-items: center;
        @include tablet-pt {
          display: block;
        }
      }
    }

    .body-third {
      @include flex-c(center);
      padding: 100px 36px;
      .body-third-content {
        @include desktop {
          width: 1194px;
        }
        .body-third-item:not(:first-child) {
          margin-top: 90px;
          @include tablet-pt {
            margin-top: 30px;
          }
        }
        .body-third-item {
          @include flex-c(space-between);
          padding: 2px;
          margin-top: 60px;
          @include tablet-lg {
            display: block;
          }
          @include tablet-pt {
            display: block;
          }
          .first-img {
            width: 786px;
            height: 408px;
            border-radius: 7px;
            @include tablet-lg {
              width: 948px;
              height: 408px;
            }
            @include tablet-pt {
              width: 696px;
              height: 361px;
            }
          }
          .item-card {
            display: inline-flex;
            padding: 30px 0;
            &.last {
              @include desktop {
                margin-top: -28px;
              }
              @include tablet-lg {
                padding: 0;
              }
            }

            .item-card-title {
              @include typo(24px, 30px, -0.5px, 600);
              color: $blue800;
              margin-bottom: 12px;
            }
            .item-card-content {
              @include typo(16px, 22px, 0);
              color: $blue600;
              text-align: start;
            }
            @include tablet-lg {
              width: 100%;

              .item-card-num {
                height: 100%;
              }
            }
            @include desktop {
              color: $blue600;
              background-color: $blue100;
              border: 1px solid $blue200;
              border-radius: 20px;
              width: 378px;
              height: 408px;
              padding: 32px 37px;
              flex-direction: column; /* flexbox를 세로 방향으로 설정 */
              justify-content: space-between;
              display: flex;
            }
          }

          /*--------좌우슬라이딩---------*/
          .slideshow {
            overflow: hidden;
            width: 786px;
            @include tablet-lg {
              width: 948px;
            }
            @include tablet-pt {
              width: 696px;
            }
            .slide {
              display: flex;
              transition: transform 1s ease-in-out;
            }
          }
        }
        .slide-btn {
          display: inline-flex;
          justify-content: center;
          width: 786px;
          @include tablet-lg {
            justify-content: start;
          }
          @include tablet-pt {
            justify-content: start;
          }
          .slide-btn-act {
            width: 32px;
            height: 8px;
            background-color: $blue600;
            border-radius: 100px;
          }
          .slide-btn-inact {
            width: 8px;
            height: 8px;
            background-color: $blue600;
            border-radius: 100px;
            opacity: 60%;
          }
        }
      }
    }

    .body-fourth {
      @include flex-c(center);
      background-color: $blue800;
      padding: 90px 36px;
      .body-fourth-width {
        text-align: center;
        width: 1194px;
        @include tablet-lg {
          width: 952px;
        }
        .body-fourth-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          gap: 30px;
          width: 1194px;
          @include tablet-lg {
            width: 952px;
          }
          @include tablet-pt {
            @include flex-c(center);
          }
          .card-lg {
            @include flex-c(space-between);
            height: 156px;
            width: 582px;
            background-color: $white;
            border-radius: 20px;
            padding: 30px;
            @include tablet-lg {
              width: 461px;
              height: 180px;
            }
            @include tablet-pt {
              width: 600px;
              height: 156px;
            }
            .card-btn {
              color: $blue900;
              text-align: start;
              @include typo(18px, 24px, 0, 600);
            }
            .card-content {
              p {
                @include typo(18px, 24px, 0);
                margin-top: 8px;
                text-align: start;
                width: 444px;
                color: $blue400;
                @include tablet-lg {
                  width: 326px;
                }
              }
            }
          }
        }
      }
    }
    .body-last {
      @include flex-c(center);
      padding: 90px 36px;
      .body-last-content {
        width: 1194px;
        @include tablet-lg {
          width: 952px;
        }
        .body-last-form {
          display: flex;
          flex-wrap: wrap;
          margin-top: 60px;
          gap: 30px;
          .body-last-form-input {
            width: 582px;
            height: 48px;
            color: black;
            background-color: white;
            border-radius: 3px;
            @include tablet-lg {
              width: 450px;
            }
          }
        }
      }
    }
    .card {
      display: "inline-block";
      width: 378px;
      height: 222px;
      background-color: $white;
      border-radius: 20px;
      padding: 30px;
      margin: 0 15px;
      @include tablet-lg {
        width: 297px;
      }
      @include tablet-pt {
        width: 696px;
        height: 167px;
        display: block;
        margin-bottom: 24px;
      }
      .card-btn {
        @include flex-c(center);
        @include typo(18px, 24px, 0, 600);
        border-radius: 100px;
        color: $white;
        background: $brand500;
        margin-right: 12px;
        width: 170px;
        height: 40px;
      }
      .card-content {
        p {
          margin-top: 40px;
          text-align: start;
          @include tablet-pt {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.main {
  .main-body {
    height: calc(100vh - 120px - 60px);
    min-height: 900px;
    padding-top: 72px;
    padding-bottom: 68px;
    @include tablet-pt {
      height: calc(100vh - 176px - 60px);
      padding-top: 36px;
      width: 696px;
    }
    @include tablet-lg {
      padding-top: 56px;
      width: 952px;
    }
    @include desktop {
      width: 1194px;
    }
    .main-body-title {
      color: $blue900;
      @include typo(28px, 38px, -0.5px, 600);
      @include desktop {
        @include typo(36px, 48px, -0.5px, 600);
      }
    }
    .main-body-subtitle {
      color: $blue400;
      @include typo(18px, 24px, 0);
      br {
        display: none;
      }
      @include tablet-pt {
        br {
          display: block;
        }
      }
      @include desktop {
        @include typo(20px, 26px, -0.5px);
      }
    }
    .main-body-category {
      @include flex-c(space-between);
      margin-top: 56px;
      margin-bottom: 16px;
      @include tablet-pt {
        display: block;
      }
      .main-body-search {
        width: 560px;
        height: 48px;
        color: $blue400;
        background-color: $blue150;
        border: 1px solid $blue200;
        @include typo(18px, 24px, 0);
        input {
          background-color: $blue150;
        }
        @include tablet-pt {
          margin-top: 16px;
          width: 696px;
        }
      }
    }

    .main-body-non {
      @include flex-c(center);
      background-color: $blue150;
      border: 1px solid $blue200;
      border-radius: 10px;
      height: 560px;
      @include tablet-pt {
        height: 476px;
      }
      @include tablet-lg {
        height: 400px;
      }
    }
    .projectCard .card {
      margin-top: 12px;
      background-color: $white;
    }
  }
}
