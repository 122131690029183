@import "./variable";

// ~ < 768px
@mixin tablet-pt {
  @media (max-width:#{$breakpoint-tablet-portrait }) {
    @content;
  }
}

// 768px <= ~ < 1024px
@mixin tablet-lg {
  @media (min-width:#{$breakpoint-tablet-portrait + 1px}) and (max-width:#{$breakpoint-tablet-large}) {
    @content;
  }
}

// 1024px <= ~
@mixin desktop {
  // @media (min-width:#{$breakpoint-tablet-large  + 1px}) and (max-width:#{$breakpoint-desktop-large}) {
  @media (min-width:#{$breakpoint-tablet-large  + 1px}) {
    @content;
  }
}

// 2560px <= ~
@mixin desktop-lg {
  @media (min-width: 2560px) {
    @content;
  }
}

//rightbar - default
@mixin bar-icon($height) {
  .default-bar {
    width: 48px;
    height: $height;
    .icon {
      width: 32px;
      height: 32px;
      margin: 8px;
      &.active {
        background-color: $blue200;
        border-radius: 3px;
      }
      img {
        margin: 8px;
        cursor: pointer;
      }
    }
  }
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// typography
@mixin typo($size, $height, $space, $weight: 400) {
  font-size: $size;
  line-height: $height;
  letter-spacing: $space;
  font-weight: $weight;
}

// 좌, 우로 배치
@mixin flex-c($jc) {
  display: flex;
  align-items: center;
  justify-content: $jc;
}

@mixin api-title {
  width: 45px;
  margin-right: 9px;
  text-align: right;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 600;
}
