@import "./scss/App.scss";
* {
  box-sizing: border-box;
}

html {
  font-family: "NotoSansKR";
}

body {
  margin: 0;
  // font-family: "NotoSansKR", "Roboto", -apple-system, BlinkMacSystemFont,
  //   "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //   "Helvetica Neue", sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
