.account {
  .account-body {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 120px - 60px);
    min-height: 900px;
    padding-top: 72px;
    @include tablet-lg {
      padding: 56px 0;
    }
    @include tablet-pt {
      padding: 36px 0;
    }
    .account-name {
      @include typo(36px, 48px, -0.5px, 600);
      color: $blue900;
      margin-top: 4px;
      margin-bottom: 36px;
      &.tablet {
        display: none;
        @include tablet-pt {
          @include typo(28px, 38px, -0.5px, 600);
          display: block;
          margin-bottom: 19px;
        }
      }
    }
    .account-menu {
      width: 200px;
      @include tablet-pt {
        display: none;
      }
    }
    .account-content {
      width: 990px;
      height: 100%;
      display: inline-block;
      @include tablet-lg {
        width: 772px;
      }
      @include tablet-pt {
        width: 696px;
      }
      .account-content-header {
        @include flex-c(space-between);
        @include tablet-pt {
          display: inline-flex;
          .account-usecount {
            display: none;
          }
        }
      }
      .account-content-title {
        @include flex-c("");
        @include typo(24px, 30px, -0.5px, 600);
        color: $blue900;
        height: 48px;
        @include tablet-pt {
          display: none;
        }
      }
      .account-tablet-menu {
        p {
          margin-left: 26px;
          margin-bottom: 19px;
        }
        display: none;
        @include tablet-pt {
          display: inline-flex;
        }
      }
      .account-content-updatepw {
        width: 100%;
        .updatepw-first {
          width: 20%;
          @include tablet-pt {
            width: 30%;
          }
        }
        .updatepw-last {
          width: 80%;
          @include tablet-pt {
            width: 70%;
          }
        }
      }
      .account-usecount-pt {
        @include flex-c(center);
        display: none;
        width: 100%;
        height: 57px;
        margin-top: -7px;
        border-bottom: 1px solid $blue200;
        @include tablet-pt {
          display: flex;
        }
      }
      .account-usecount-content {
        border-bottom: 1px solid $blue200;
        width: 100%;
        margin-top: -7px;
        display: inline-flex;
        min-height: 694px;
        max-height: 694px;
        @include tablet-pt {
          margin-top: 0px;
          min-height: calc(694px - 57px);
          max-height: calc(694px - 57px);
        }
        .account-sepbar {
          margin-left: 10px;
          margin-right: 7px;
          border-left: 1px solid $blue300;
          display: inline-block;
          height: 12px;
          vertical-align: 3px;
        }
      }
    }
  }
}
