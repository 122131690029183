// SigninContent css
.signin {
  .signin-body {
    height: calc(100vh - 120px - 60px);
    min-height: 900px;
    @include flex-c(center);
  }
}

// SignupContent css
.signup {
  .signup-body {
    height: calc(100vh - 120px - 60px);
    min-height: 900px;
    @include flex-c(center);
  }
}
