$typos:
  "Body1" "_B" 24px 30px -0.5px,
  "Body1" "_R" 24px 30px -0.5px,
  "Body2" "_B" 20px 26px -0.5px,
  "Body2" "_R" 20px 26px -0.5px,
  "Body3" "_B" 18px 24px 0,
  "Body3" "_R" 18px 24px 0,
  "Body4" "_B" 16px 22px 0,
  "Body4" "_R" 16px 22px 0,
  "Body5" "_B" 14px 20px 0,
  "Body5" "_R" 14px 20px 0,
  "Body6" "_B" 13px 18px 0,
  "Body6" "_R" 13px 18px 0,
  "Body7" "_B" 12px 16px 0,
  "Body7" "_R" 12px 16px 0,
  "H2" "_R" 72px 86px -0.5px,
  "H3" "_R" 36px 48px -0.5px,
  "H3" "_B" 36px 48px -0.5px,
  "H4" "_B" 28px 38px -0.5px;

@each $name, $bold, $size, $height, $space in $typos {
  .#{$name}#{$bold} {
    font-size: $size;
    line-height: $height;
    letter-spacing: $space;
    font-weight: 400;
    @if $bold == "_B" {
      font-weight: 600;
    }
    &.ml4 {
      margin-left: 4px;
    }
  }
}

$sizes: "-9", "-4", "-3", "-2", "-1", "2", "4", "6", "7", "8", "9", "10", "11",
  "12", "14", "16", "17", "20", "22", "24", "28", "32", "36", "40", "48", "52",
  "56", "64", "72", "90", "300";
@each $size in $sizes {
  .m#{$size} {
    margin: unquote($size + "px");
  }
  .mr#{$size} {
    margin-right: unquote($size + "px");
  }
  .ml#{$size} {
    margin-left: unquote($size + "px");
  }
  .mt#{$size} {
    margin-top: unquote($size + "px");
  }
  .mb#{$size} {
    margin-bottom: unquote($size + "px");
  }
  .ft#{$size} {
    font-size: unquote($size + "px");
  }
}

$pSizes: "8", "10", "16";
@each $size in $pSizes {
  .p#{$size} {
    padding: unquote($size + "px");
  }
  .pr#{$size} {
    padding-right: unquote($size + "px");
  }
  .pl#{$size} {
    padding-left: unquote($size + "px");
  }
  .pt#{$size} {
    padding-top: unquote($size + "px");
  }
  .pb#{$size} {
    padding-bottom: unquote($size + "px");
  }
}

.roboto_B {
  font-family: "Roboto_B";
}

.roboto_R {
  font-family: "Roboto_R";
}
